<template>
    <div>
        <CRow> 
            <CCol md="12">   
                <CButton :color="menu == 'general' ? 'info' : 'secondary'" size="sm" @click="menu != 'general' ? showGraph('general') : '';" style="margin-right: 5px;">Resumen General</CButton>
                <CButton :color="menu == 'work-pending' ? 'info' : 'secondary'" size="sm" @click="menu != 'work-pending' ? showGraph('work-pending') : '';" style="margin-right: 5px;">Trabajo Pendiente</CButton>
            </CCol>           
        </CRow>
    </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

export default {
    name: 'GraphsMenu',
    props: {
        menu: {
            type: String,
            default() {
                return ""
            }
        },
    },
    components : {
	},
    data: function () {
		return {
            items: {},
            root: "",
        }
    },
    computed: {
        
    },
    methods: {
        showGraph: async function(graph){
            window.location = '/#/graphs/'+graph;
        },
    },
  	mounted: async function(){        
    },
    beforeDestroy() {
        if (this.root) {
        this.root.dispose();
        }
  }
}

</script>
