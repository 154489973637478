import axios from 'axios'

export default {
    getMonths: () => axios.post('/admin/dashboard/months'),
    //Servicios Generales
    getCustomers: (params) => axios.post('/admin/dashboard/customers',params),
    getCollaborators: (params) => axios.post('/admin/dashboard/collaborators',params),

    //Servicios Resumen General mes Actual default
    getMetrics: (params) => axios.post('/admin/dashboard/metrics',params),
    getGraphs: (params) => axios.post('/admin/dashboard/graphs1',params),

    //Servicios trabajos pendientes
    getGraphsPending: (params) => axios.post('/admin/dashboard/graphs2',params),
}